.consultancy {
    width: 100%;
}

.consultancy h1 {
    padding: 20px;
    text-transform: uppercase;
    background-color: #815EC7;
    border-radius: 10px;
    width: max-content;
    margin: 10px auto;
    color: #fff;
}

.consultancy p {
    text-indent: 50px;
}

.consultancy p::first-letter {
    font-size: 30px;
}
.home-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
}
.home-col {
    flex: 6;
    flex-direction: row;
}

.home-col:first-child {
    padding: 0 100px;
    text-align: justify;
}
.consultancy .home-col img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 1000px 0 0 1000px;
}
.home-col h2 {
    color: #08B6DD;
    text-align: center;
    padding-top: 30px;
}
@media (max-width:768px) {
    .consultancy .home-row {
        flex-direction: column;
    }

    .home-col:first-child {
        padding: 20px;
    }
}