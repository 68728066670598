.container {
    width: 100%;
    height: auto;
    overflow: hidden;
}
#home {
    width: 100%;
    background-color: #fff;
}
.home-cover {
    width: calc(100% - 40px);
    background-color: #E8F3FB;
    margin: 20px auto;
    overflow: hidden;
    box-sizing: content-box;
}

.header {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
}

.logo {
    width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nav-bar {
    color: #fff;
    line-height: 100px;
}
.nav-bar ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-bar li {
    padding: 10px;
    list-style: none;
    position: relative;
}

.nav-bar li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #08B6DD;
    left: 0;
    bottom: 40px;
    opacity: 0;
    transition: all 0.8s;
}

.nav-bar li:hover::after,
.nav-bar li:focus::after {
    opacity: 1;
}

.nav-bar a {
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    color: #815EC7;
    cursor: pointer;
    transition: all 0.2s;
}

.nav-bar a:hover, .nav-bar a.active {
    color: #08B6DD;
}

.socials i {
    padding: 20px;
    color: #08B6DD;
    cursor: pointer;
    margin-right: 5px;
}
.menu {
    color: #08B6DD;
    font-weight: 900;
    font-size: 30px;
    display: none;
}

.nav-bar-mobile {
    display: none;
}
.nav-bar-hide {
    display: none;
}
/* Mobile header */
@media(max-width: 768px) {
    .container {
        overflow: hidden;
    }
    .home-cover {
        width: 100%;
    }
    .nav-bar {
        display: none;
    }
    .nav-bar-mobile {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        line-height: 10px;
        position: absolute;
        top: 120px;
        right: 20px;
        width: 30%;
        height: 100%;
        padding: 10px 0;
        z-index: 999;
    }

    .nav-bar-mobile li {
        padding: 10px;
        list-style: none;
        position: relative;
    }

    .nav-bar-mobile a {
        text-decoration: none;
        font-weight: 500;
        font-size: 18px;
        color: #815EC7;
        cursor: pointer;
        transition: all 0.2s;
    }

    .nav-bar-mobile ul {
        width: 100%;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .nav-bar-mobile li,  .nav-bar-mobile a  {
        flex-direction: column;
        width: 100%;
        color: #fff;
    }
    .nav-bar-hide {
        display: none;
    }


    .nav-bar ul, .nav-bar li,  .nav-bar a  {
        flex-direction: column;
        width: 100%;
        color: #fff;
    }
    .nav-bar li::after {
        width: 0;
        height: 0;
    }

    .socials {
        display: none;
    }
    .socials .phone {
        display: block !important;
    }
    .menu {
        display: block;
    }
    .home-row {
        flex-direction: column;
    }
    .no1 { 
        line-height: 30px !important;
        padding: 0 10px;
    }
    .home-desc {
        padding-top: 100px;
    }
    .home-img img {
        border-radius: 0 1000px 0 0;
    }

    .about-col {
        display: none;
    }

    .about-row {
        flex-direction: column;
    }
    .about-col2:last-child p {
        padding: 20px 0 !important;
    }

    .services-col2:last-child {
        margin-left: 20px !important;
    }
    .services-cover {
        margin-bottom: 20px !important;
    }
    #contact {
        margin-bottom: 50px;
    }
    .top-cover {
        height: auto !important;
    }

    .botton-cover {
        height: auto;
    }

    .client-row {
        flex-direction: column;
    }

    #clients {
        margin-top: 680px;
    }
    .client-col1 {
        width: 100%;
    }

    .client-col1 img {
        height: 100px !important;
    }
}
@media (max-width: 425px) {
    .no1 {
        width: 100%;
    }

    .home-cover .home-col:first-child {
        padding: 0 20px;
    }
    .home-desc {
        padding-top: 0;
    }
}

@media (max-width: 320px) {
    #clients {
        margin-top: 650px;
    }
}
@media ((min-width: 321px) and (max-width: 424px)) {
    #clients {
        margin-top: 710px;
    }
}
@media ((min-width: 769px) and (max-width: 1350px)) {
    #clients {
        margin-top: 300px;
    }
}

.home-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
}


.home-col {
    flex: 6;
}

.home-cover .home-col:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
} 

.no1 {
 padding: 0 30px;
 background-color: #08B6DD;
 margin-bottom: 30px;
 line-height: 80px;
}
.no1 h1 {
    color: #E8F3FB;
}

.home-desc {
    padding-bottom: 50px;
    text-indent: 50px;
}

.home-desc p::first-letter{
    font-size: 50px;
}

.home-img {
    width: 100%;
    height: 100%;
}
.home-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 0 0 1000px;
}

.home-btn {
    bottom: 50px;
    left: 35%;
    padding: 20px 40px;
    color: #000;
    border: none;
    position: relative;
    z-index: 999 !important;
    cursor: pointer;display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right,blue, red,blue, red);
    box-shadow: 2px 0 10px red, -2px 0 10px blue;;
    border-radius: 20px;
}
.home-btn::after {
    content: '';
    position: absolute;
    background-color: #E8F3FB;
    width: 93%;
    height: 80%;
    z-index: -7 !important; 
    border-radius: 20px;
    display: block; /* Ensure the pseudo-element is visible */
    pointer-events: none; /* Prevent the pseudo-element from interfering with button interaction */    
} 

.home-btn:hover {
    z-index: -1;
    cursor: pointer;
    background-color: red;
}

/* About */
#about{
    width: 100%;
    height: auto;
    padding-top: 20px;
    background-color: #fff;
}
.about-cover {
    width: 80%;
    margin: auto;
    position: relative;
}
.about-col {
    flex: 3;
    text-align: center;
}

.about-row {
    display: flex;
    align-items: center;
    justify-content: center;  
    padding-top: 20px;
}
.about-col2:first-child {
    flex: 5;
}
.about-col2:last-child {
    flex: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.about-row img {
    width: 100%;
    border-radius: 10px;
}

.about-cover h1 {
    background-color: #815EC7;
    width: max-content;
    padding: 10px 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    color: #fff;
    margin: 0 auto;
    margin-bottom: 20px;
}

/* .learn-more {
    width: max-content;
    padding: 10px 40px;
    margin-top: 10px;
    padding: 20px 60px;
    color: #000;
    border: none;
    outline: none;
    position: relative;
    border-radius: 5px;
    background: linear-gradient(to right, #00FFA3, #DC1FFF);
    cursor: pointer;
} */
.learn-more h2 {
    height: max-content;
    cursor: pointer;
}
.learn-more h2 a {
    color: #08B6DD;
    text-decoration: none;
}
/* .learn-more::before {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    background-color: white;
    border-radius: 2px;
} */

.learn-more {
    padding: 20px 60px;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(to right, #00FFA3, #DC1FFF);
    cursor: pointer;
  }
.learn-more::before {
content: "";
position: absolute;
left: 1px;
right: 1px;
top: 1px;
bottom: 1px;
border-radius: 4px;
background-color: white;
z-index: -1;
transition: 200ms;
}
.learn-more::after {
content: attr(data);
font-size: 16px;
background: linear-gradient(to left, #00FFA3, #DC1FFF);
background-clip: text;
-webkit-background-clip: text;
color: transparent;
transition: 200ms;

}
.learn-more:hover::before {
opacity: 50%;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
/* box-shadow: 20px 0 40px red, -20px 0 40px blue; */
}
.learn-more:hover::after{
color: white;
}

.about-col2:last-child p {
    padding: 0 70px;
    text-align: justify;
    margin-bottom: 20px;
}

/* ABout desc */
#about-desc {
    width: 100%;
    height: auto;
    background-color: #E8F3FB;
}

#about-desc h1 {
    text-align: center;
    font-weight: 900;
    font-size: 40px;
    padding: 20px;
}

.about-desc-col {
    /* width: 100%; */
    flex-basis: 32%;
    /* padding: 20px 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* .about-desc-col:nth-child(2) {
    padding: 0 20px;
} */
.about-desc-col:nth-child(2) h2{
    color: #815EC7;
}

.about-desc-col img {    
    width: 100%;
    height: 300px; 
    object-fit: cover;
    border-radius: 10px;
}
.about-desc-col h2 {
    flex-basis: 30%;
    text-transform: uppercase;
    font-size: 25px;
    padding-top: 20px;
}
.about-desc-col p {
    flex-basis: 30%;
    padding: 5px 30px;
}

/* Services */
.services-cover {
    width: 80%;
    margin: 0 auto;
}

.services-cover h1 {
    width: max-content;
    padding: 10px 80px;
    background-color: #815EC7;
    border-radius: 10px;
    text-align: center;
    font-size: 40px;
    color: #fff;
    margin: 20px auto;   
    padding: 10px 80px; 
    overflow: hidden;
}

.services-col1:first-child {
    flex: 8;
}
.services-col1:first-child h2 {
    text-align: center;
    color: #08B6DD;
    font-weight: 900;
    font-size: 35px;
}
.services-col1:first-child p {
    text-align: justify;
    margin: 10px 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.services-col1 a, .services-col2 a {
    padding: 20px;
    font-size: 20px;
    text-decoration: none;
    color: #08B6DD;
    cursor: pointer;
}

.services-col1:last-child {
    flex: 4;
}

.services-col1:last-child img {
    width: 100%;
}


.services-col2:first-child {
    margin-top: 50px;
    flex: 4;
}
.services-col2:first-child img {
    width: 100%;
}
.services-col2:last-child {
    margin-left: 200px;
    flex: 8;
}

.services-col2:last-child h2 {
    text-align: center;
    color: #08B6DD;
    font-weight: 900;
    font-size: 35px;
    padding-bottom: 20px;
}

.services-col2:last-child p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
#contact {
    width: 100%;
    height: auto;
    background-color: #E8F3FB;
}

#contact h1 {
    color: #815EC7;
    font-size: 35px;
    text-align: center;
    padding: 20px;
}

#contact p {
    text-align: center;
}
.contact-cover {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.top-cover {
    width: 100%;
    max-height: 50vh;
    flex: 1;
}

.botton-cover {
    width: 100%;
    min-height: 50vh;
    background-color: #fff;
    flex: 1;
}

.contact-info {
    width: 80%;
    height: auto;
    margin: 50px auto;
    background-color: #fff;
    position: relative;
    border-radius: 20px;
}

.contact-col:first-child {
    flex: 4;
    margin: 20px;
}

.contact-inner-col {
    width: 100%;
    padding: 20px;
    background-color: #08B6DD;
    color: #fff;
    border-radius: 20px;
}
.contact-inner-col h1 {
    color: #fff !important;
}

.contact-col:last-child {
    flex: 8;
}
.content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.icon {
    flex: 2;
    padding-bottom: 20px;
}
.info {
    flex: 10;
    padding-bottom: 20px;
}

.info li {
    list-style: none;
}


.info p {
    text-align: left !important;
}
/* form */
.contact-col form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px;
}

#name, #email {
    border-width: 0 0 1px 0;
}

input {
    margin: 0 50px 20px 0;
    padding: 10px;
}
 

textarea, #subject {
    width: 100%;
    border-width: 0 0 1px 0;
}
#btn {
    margin: 20px auto;
    padding: 10px 20px;
    font-weight: 900;
    font-size: 16px;
    color: #fff;
    background-color: #08B6DD;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

/* Clients */
#clients {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
}

#clients h1 {
    width: max-content;
    padding: 10px 80px;
    background-color: #815EC7;
    border-radius: 10px;
    text-align: center;
    font-size: 40px;
    color: #fff;
    margin: 20px auto;
    padding: 5px 50px;
    overflow: hidden;
}
.clients {
    width: 100%;
    height: 40vh;
    background-color: #000;
}

.clients-cover {
    width: 100%;
    height: 40vh;
}
.clients-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.client-col {
    flex: 1;
    width: 80%;
    margin: 0 auto;
}

#learn-btn {
    position: relative;
    padding: 20px 100px;
    margin-left: auto;
}

.client-row {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.client-col1 {
    flex-basis: 30%;
    height: 150px;
    box-shadow: 2px 0 10px #815EC7;
    padding: 20px;
    margin: 10px !important;
    margin: 0 auto;
    /* overflow: hidden; */
}

.client-col1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.footer {
    width: 100%;
    height: 50px;
    color: #fff;
    background-color: #08B6DD;
    line-height: 50px;
    display: flex;
    justify-content: center;
}

#consult {
    color: #815EC7;
}