.services {
    width: 100%;
    height: 100vh;
}

.services h1 {
    text-transform: uppercase;
    text-align: center;
    background-color: #815EC7;
    width: max-content;
    padding: 10px 40px;
    border-radius: 10px;
    color: #fff;
    margin: 0 auto;
    position: absolute;
    top: 100px;
    transform: translate(180%);
}

.services-top {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
}

.services-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.services-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
}

.services-col {
    flex-basis: 15%;
    padding: 10px;
    box-shadow: 2px 0 10px #815EC7;
    margin: 10px;
    text-align: center;
}