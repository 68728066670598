.services {
    width: 100%;
    height: 100vh;
}

.services h1 {
    text-transform: uppercase;
    text-align: center;
    background-color: #815EC7;
    width: max-content;
    padding: 10px 40px;
    border-radius: 10px;
    color: #fff;
    margin: 0 auto;
    position: absolute;
    top: 100px;
    transform: translate(180%);
}

.services-top {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
}

.services-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.services-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
}

.services-col {
    flex-basis: 15%;
    padding: 10px;
    box-shadow: 2px 0 10px #815EC7;
    margin: 10px;
    text-align: center;
    cursor: pointer;
}
.services-col:hover {
    background-color: #d1cfd4;
}

.leadership {
    width: 40%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 10px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 25%;
    text-align: center;
}
.no-display {
    display: none;
}

.leadership i {
    font-size: 50px;
    padding-bottom: 20px;
    float: right;
    padding-right: 5px;
}
.leadership h3 {
    padding-top: 50px;
    font-size: 30px;
}

.leadership p {
    padding: 20px;
}

.services h2 {
    display: none;
}

@media (max-width: 768px) {
    .services {
        width: 100vw;
        height: auto;
    }

    .services h1 {
        display: none;
    }
    .services h2 {
        display: block;
        text-transform: uppercase;
        text-align: center;
        background-color: #815EC7;
        width: max-content;
        padding: 10px 40px;
        border-radius: 10px;
        color: #fff;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .services-top {
        margin-bottom: 10px !important;
    }
    .leadership {
        width: 80%;
        left: 10%;
    }
}