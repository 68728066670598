* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "EB Garamond", serif;
}

.back-btn {
  border: 0;
  background-color: #08B6DD;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  float: right;
  cursor: pointer;
  margin: 0 20px;
}