.training {
    width: 100vw;
    height: 100vh;
    padding-top: 100px;
}

.training-cover {
    width: 80%;
    height: 95%;
    margin: 0 auto;
    border-radius: 25px;
    background-color: #815EC7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.training-img {
    flex: 6;
    width: 100%;
    height: 100%;
}

.training-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 25px 0 0 25px;
    
}
.training-content {
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
    flex: 6;
    background-color: #fff;
}

.training-content h1 {
    background-color: #08B6DD;
    width: max-content;
    padding: 10px 30px;
    border-radius: 10px;
    margin: 0 auto;
    color: #fff;
}
.training-content p {
    text-indent: 50px;
    text-align: justify;
    line-height: 25px;
    padding-top: 20px;
}
.training-content p::first-letter {
    font-size: 40px;
}
@media (max-width: 768px) {
    .training-cover {
        width: 100%;
        height: auto;
        flex-direction: column;
    }
    .training {
        padding-top: 0;
    }
    .training-img img {
        border-radius: 20px;
    }
}