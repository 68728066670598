.inclusivity {
    padding: 20px;
}

.inclusivity h2 {
    text-align: center;
    color: #815EC7;
}

.about-desc-cover p {
    text-align: center;
}

.inclusivity .home-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 20px 50px;
}

.inclusivity .about-desc-col {
    flex-basis: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inclusivity #about-desc {
    width: 100%;
    height: auto;
    background-color: #E8F3FB;
}

.inclusivity .material-symbols-outlined {
    color: #000;
    font-size: 40px;
}
.inclusivity-contact {
    padding: 20px;
    font-size: 20px;
}